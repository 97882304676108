import { useNavigate } from '@remix-run/react'
import React from 'react'
import { Button } from '~ui'
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

export const Error: React.FC<{
  error?: string
  onReload?: () => void
  allowReload?: boolean
}> = ({ error, onReload, allowReload = true }) => {
  const navigate = useNavigate()

  const handleReload = React.useCallback(() => {
    if (onReload) {
      onReload()
    } else {
      navigate('.', { replace: true })
    }
  }, [onReload])

  return (
    <div className="flex flex-col items-center pt-2 pb-4">
      <WrenchScrewdriverIcon className="text-error-500 w-10 h-10" />
      <h3 className="mt-2 text-lg font-semibold text-error-500">
        Something went wrong
      </h3>
      <p className="mt-1 text-sm text-error-500">
        {error}
      </p>
      <div className="mt-6">
        {allowReload ? <Button onClick={handleReload}>Reload</Button> : null}
      </div>
    </div>
  )
}
